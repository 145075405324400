import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { connect } from 'react-redux';
import PrivateRoute from './helper/privateRoute';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const ForgetPassword = React.lazy(() => import('./views/Pages/ForgetPassword/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const SignUp = React.lazy(() => import('./views/Pages/SignUp/signup'));
const CheckOtpPage = React.lazy(() => import('./views/Pages/otpPage/otpPage'));
const Registration = React.lazy(() => import('./views/Registration/registration'));
const MarketingPage = React.lazy(() => import('./views/Pages/MarketingPage/MarketingPage'));
const LuckyDraw = React.lazy(() => import('./views/Pages/LuckyDraw/LuckyDraw'));

class App extends Component {

  render() {
    // if(this.props.loadingResult == true){
    //   return(
    //     <div className="animated fadeIn pt-3 text-center">Loading...</div>
    //   )
    // };
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/home" name="Marketing Page" render={props => <MarketingPage {...props} />} />
            <Route exact path="/luckyDraw" name="LuckyDraw Page" render={props => <LuckyDraw {...props} />} />
            <Route exact path="/signup" name="SignUp Page" render={props => <SignUp {...props} />} />
            <Route exact path="/registration" name="Registration Page" render={props => <Registration {...props} />} />
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/checkOtp/:id" name="Check OTP Page" render={props => <CheckOtpPage {...props} />} />
            <Route exact path="/forget-password" name="Forget Password" render={props => <ForgetPassword {...props} />} />
            <Route exact path="/resetpassword/:token" name="Reset Password" render={props => <ResetPassword {...props} />} />
            {/* <Route exact path="**" name="Page 404" render={props => <Page404 {...props}/>} /> */}
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}


function mapStateToProps(state, ownProps) {

  return {
    loadingResult: state.loadingR,

  };
}
export default connect(mapStateToProps)(App);